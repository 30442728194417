window.addEventListener('load',function(){
			let hed = document.querySelector('#header'),
					obj = $('#offsetHeight').offset().top;
			
    window.addEventListener('scroll',function(){
    let wScroll = this.scrollY;
    if(wScroll > obj){
      hed.classList.add('menu__scroll');
    } else {
      hed.classList.remove('menu__scroll');
    }
		});
	(function(){
		$('.header__menu').on('click',function(){
			let _this = $(this);
			if(_this.hasClass('active')){
				_this.removeClass('active')
				$('.menu__wrapper').fadeOut(300,function(){
					if($(this).css('display') == 'none'){
						$(this).removeAttr('style');
						}
				});
			}else {
				_this.addClass('active')
				$('.menu__wrapper').fadeIn(300);
			}
		});
	})();
	(function(){
		$("#nav__menu").on("click","a", function (event) {
			event.preventDefault();
			//идентификатор блока с атрибута href
			var id  = $(this).attr('href'),
			//высоту от начала страницы до блока на который ссылается якорь
				top = $(id).offset().top;
			//анимация перехода на расстояние - top за 1500 мс
			$('body,html').animate({scrollTop: top}, 1500);
		});
	})();
});