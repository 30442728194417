$(document).ready(function(){

	(function(){
		var _thisForma;
		$('.btn__form').on('click', function(){
					$('.form__first').fadeIn();
					_thisForma = $('.form__first');
		});

		$('.form__close').on('click',function(){
			$('.form__first').fadeOut();
			$('.popup-thanks').fadeOut();
			$('.popup-err').fadeOut();
		});
	
		$('#form__btn-thanks').on('click', function() {
			$('.popup-thanks').fadeOut();
		});
		$('#form__btn-err').on('click', function() {
			$('.popup-err').fadeOut();
			_thisForma.fadeIn();
		});
	})();
	(function(){
		$('#first-form').submit(function(e) {
			var $mainForm = $(this);
			$.ajax({
				type: $mainForm.attr('method'),
				url: $mainForm.attr('action'),
				data: $mainForm.serialize()
			}).done(function() {
				$('.form__first').fadeOut();
				$('.popup-thanks').fadeIn();
				$('form[name=first-form]').trigger('reset');
			}).fail(function() {
				$('.form__first').fadeOut();
				$('.popup-err').fadeIn();
				$('form[name=first-form]').trigger('reset');
			});
			//отмена действия по умолчанию для кнопки submit
			e.preventDefault();
		});
		$('#questions__form').submit(function(e) {
			var $mainForm = $(this);
			$.ajax({
				type: $mainForm.attr('method'),
				url: $mainForm.attr('action'),
				data: $mainForm.serialize()
			}).done(function() {
				$('.form__first').fadeOut();
				$('.popup-thanks').fadeIn();
				$('form[name=questions__form]').trigger('reset');
			}).fail(function() {
				$('.form__first').fadeOut();
				$('.popup-err').fadeIn();
				$('form[name=questions__form]').trigger('reset');
			});
			//отмена действия по умолчанию для кнопки submit
			e.preventDefault();
		});
	})();
});
// .btn__form // вызов формы
// btn__form-sumbit // вызов только или успешной или проваленой отпраки