(function(){
	$('#hero__form').submit(function(e) {
		var $mainForm = $(this);

		$.ajax({
			type: $mainForm.attr('method'),
			url: $mainForm.attr('action'),
			data: $mainForm.serialize()
		}).done(function() {
			$('.form__first').fadeOut();
			window.open('/assets/download/partner.pdf');
			$('form[name=hero__form]').trigger('reset');
		}).fail(function() {
			$('.form__first').fadeOut();
			$('form[name=hero__form]').trigger('reset');
		});
		//отмена действия по умолчанию для кнопки submit
		e.preventDefault();
	});
})();