(function(){
	let catItem = $('.catalogue__link'),
			galItem = $('.gallery__item'),
			close = $('.popup__close');
	catItem.on('click',function(e){
		e.preventDefault()
		let _this = $(this),
				parent = _this.closest('.catalogue__item'),
				popup = parent.find('.catalogue__popup'),
				img = parent.find('.gallery__img-pic').first(),
				firstImg = img.closest('.gallery__item'),
				display = parent.find('.gallery__display');
				firstImg.addClass('active')
								.siblings()
								.removeClass('active');
				display.attr('src',img.attr('src'));
				popup.fadeIn();
	});
	galItem.on('click',function(){
		let _this = $(this),
				galImg = _this.find('.gallery__img-pic'),
				parent = _this.closest('.popup__left'),
				display = parent.find('.gallery__display'),
				src = galImg.attr('src');
			display.attr('src',src);
		if(_this.hasClass('active')){
				_this.siblings()
						 .removeClass('active');
		}else{
			_this.addClass('active')
					 .siblings()
					 .removeClass('active');
		}

	});

	close.on('click',function(){
		var _this = $(this);
		_this.closest('.catalogue__popup').fadeOut()
	});


})();
[].forEach.call(document.querySelectorAll('.gallery__img-pic[data-src]'),function(img){
	img.setAttribute('src',img.getAttribute('data-src'));
	img.onload = function(){
		img.removeAttribute('data-src');
	};
});
