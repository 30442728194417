$(document).ready(function(){
	$('.slick__slider').slick({
		infinite: true,
		speed: 2000,
		autoplay: true,
		autoplaySpeed: 7000,
		pauseOnHover: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: '<button type="submit" class="services-slider_prev"></button>',
		nextArrow: '<button type="submit" class="services-slider_next"></button>',
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 767,
				settings: {
					adaptiveHeight: true,
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		]
	});
});